exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-andean-alpaca-spirit-statue-sculpture-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/andean-alpaca-spirit-statue-sculpture/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-andean-alpaca-spirit-statue-sculpture-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-ape-homage-bored-ape-yacht-club-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/ape-homage-bored-ape-yacht-club/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-ape-homage-bored-ape-yacht-club-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-brick-blocker-lego-guy-2-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/brick-blocker-lego-guy-2/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-brick-blocker-lego-guy-2-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-brick-blocker-lego-guy-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/brick-blocker-lego-guy/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-brick-blocker-lego-guy-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-drunken-dragon-sage-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/drunken-dragon-sage/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-drunken-dragon-sage-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-eye-of-the-hand-01-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/eye-of-the-hand-01/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-eye-of-the-hand-01-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-kintsugi-kraken-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/kintsugi-kraken/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-kintsugi-kraken-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-lady-high-heels-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/lady-high-heels/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-lady-high-heels-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-maki-sushi-monster-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/maki-sushi-monster/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-maki-sushi-monster-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-mischievous-charlie-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/mischievous-charlie/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-mischievous-charlie-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-paper-waltz-statue-sculpture-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/paper-waltz-statue-sculpture/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-paper-waltz-statue-sculpture-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-retrobot-groove-dancing-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/retrobot-groove-dancing/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-retrobot-groove-dancing-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-sardine-sentinel-evolution-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/sardine-sentinel-evolution/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-sardine-sentinel-evolution-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-sardine-sentinel-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/sardine-sentinel/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-sardine-sentinel-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-the-awakening-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/the-awakening/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-the-awakening-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-thunderbolt-buddy-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/thunderbolt-buddy/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-thunderbolt-buddy-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-whovian-sentinel-dalek-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/whovian-sentinel-dalek/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-whovian-sentinel-dalek-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-zen-banana-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/zen-banana/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-zen-banana-index-mdx" */),
  "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-zen-shroomie-warrior-index-mdx": () => import("./../../../src/pages/products/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/carloscastillo/Code/mr-castle-gatsby-site/sculptures/zen-shroomie-warrior/index.mdx" /* webpackChunkName: "component---src-pages-products-mdx-frontmatter-slug-js-content-file-path-sculptures-zen-shroomie-warrior-index-mdx" */)
}

